.no-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
    flex-direction: column;
}

.no-link > h1 {
    color: #ff4700;
    font-size: 42px;
}

.no-link > h4 {
    color: #3b3c44;
    font-size: 26px;
    text-align: center;
}

.background-icon {
    width: 100%;
    height: 60%;
    background-size: contain;
    background-repeat: no-repeat;
}
html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  color: #66748d;
}

body {
  background: url('pictures/fba7da61.png');
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

body div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

div::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

div::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

div::-webkit-scrollbar-thumb {
  background: #79808c;
  border: 0 none #ffffff;
  border-radius: 50px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #606773;
}

div::-webkit-scrollbar-thumb:active {
  background: #79808c;
}

div::-webkit-scrollbar-track {
  background: #d4d4d4;
  border: 0 none #ffffff;
  border-radius: 50px;
}

div::-webkit-scrollbar-track:hover {
  background: #cacaca;
}

div::-webkit-scrollbar-track:active {
  background: #c4c4c4;
}

div::-webkit-scrollbar-corner {
  background: transparent;
}

div#root {
  display: flex;
  min-width: 1px;
  min-height: 1px;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.app {
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 1px;
  min-height: 1px;
  flex-direction: column;
}

.app-container {
  height: 100%;
  width: 100%;
  display: flex;
  min-width: 0;
  min-height: 0;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-screen {
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-icon {
  background: url('pictures/Ic_delete_48px.svg');
  background-size: 20px;
  background-position: center;
}

.edit-icon {
  background: url('pictures/pencil.svg');
  background-size: 16px;
  background-position: center;
}

.copy-icon {
  background: url('pictures/copy.svg');
  background-size: 16px;
  background-position: center;
}

.search-icon {
  background: url('pictures/magnifier.svg');
  background-size: 16px;
  background-position: center;
}

.move-icon {
  background: url('pictures/angle-right-dark-grey.svg');
  background-size: 26px;
  background-position: center;
}

.open-label-icon {
  background: url('pictures/open-label.svg');
  background-size: 16px;
  background-position: center;
}

.closed-label-icon {
  background: url('pictures/closed-label.svg');
  background-size: 16px;
  background-position: center;
}

.stick-figure-icon {
  background: url('pictures/stick-figure.svg');
  background-position: center;
}

.logout-icon {
  background-image: url('pictures/log-out.svg');
  width: 30px;
  height: 30px;
  background-size: cover;
  position: fixed;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 100;
}

.drop-shadow {
  box-shadow: 0 2px 6px rgba(0,0,0,0.15);
}

.correcting-header {
  padding-right: 40px;
}

.correcting-header-button {
  height: 30px;
  line-height: 30px;
}

.content {
  display: flex;
  padding: 0 5px;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  overflow: auto;
}

.admin-user-selector {
  position: fixed;
  top: 50px;
  right: 10px;
  z-index: 100;
  max-width: 200px;
}
.usage {
    width: 100%;
}

.usage-days {
    display: flex;
    align-items: center;
    border-bottom: 20px solid transparent;
}

.usage-day-input {
    width: 40px;
    border: none;
    border-bottom: 1px solid #adadad;
    color: #66748d;
    font-size: 16px;
    outline: none;
    text-align: center;
    flex-shrink: 0;
}

.usage-days-text {
    padding: 0 10px;
    flex-shrink: 0;
}

.usage-button {
    padding: 3px 8px;
    cursor: pointer;
    flex-shrink: 0;
}

.space-between {
    width: 100%;
}

.space-between-buttons {
    width: 10px;
    flex-shrink: 0;
}

.usage-panel-open-label {
    flex-shrink: 0;
}

.usage-panel-open-icon {
    font-weight: 700;
    width: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: 12px;
    flex-shrink: 0;
}

.usage-panel-header {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
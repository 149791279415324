.tab-selector.selected {
    background-color: rgb(193, 197, 201);
}

.tab-header {
    display: flex;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    border-bottom: 1px solid rgb(193, 197, 201);
}

.tab-header {
    display: flex;
    border-bottom: 1px solid grey;
}

.tab-selector {
    padding: 5px 10px;
    width: 100%;
    text-align: center;
}

.tab-container {
    overflow: auto;
}

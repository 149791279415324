body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.myButton {
  -moz-box-shadow: inset 0 34px 0 -15px #b54b3a;
  -webkit-box-shadow: inset 0 34px 0 -15px #b54b3a;
  box-shadow: inset 0 34px 0 -15px #b54b3a;
  background-color: #a73f2d;
  border: 1px solid #241d13;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: bold;
  padding: 9px 23px;
  text-decoration: none;
  text-shadow: 0 -1px 0 #7a2a1d;
  text-align: center;
}

.myButton:hover {
  background-color: #b34332;
}

.myButton:active {
  position: relative;
  top: 1px;
}

button.myButton[disabled] {
  background-color: #747474;
  box-shadow: inset 0 34px 0 -15px #818181;
}

.button {
  box-shadow: inset 0 34px 0 -15px #b54b3a;
  background-color: #a73f2d;
  border: 1px solid #241d13;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 0 25px;
  text-decoration: none;
  text-shadow: 0 -1px 0 #7a2a1d;
  text-align: center;

}

.button:hover {
  background-color: #b34332;
}

.button:active {
  position: relative;
  top: 1px;
}

.button[disabled] {
  background-color: #747474;
  box-shadow: inset 0 34px 0 -15px #818181;
  cursor: initial;
}

textarea {
  box-sizing: border-box;
}

.padding {
  padding: 10px;
}

.padding-left-none {
  padding-left: 0;
}


.border-bottom {
  border-bottom: 1px solid #a9a9a9;
}

.capitalize {
  text-transform: capitalize;
}

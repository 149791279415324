.login-container {
    display: flex;
    min-width: 1px;
    min-height: 1px;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.login-content {
    max-width: 300px;
    width: 80%;
    z-index: 100;
}

.login-content {
    background: white;
    border-radius: 5px;
    padding: 5px;
}

.login {
    display: flex;
    min-width: 1px;
    min-height: 1px;
    flex-direction: column;
}

.login input {
    line-height: 25px;
    padding: 0 5px;
    outline: none;
    border: 1px solid rgb(163, 168, 173);
}

.login > * {
    margin: 5px 0;
}

.error-message {
    font-size: 13px;
    color: red;
    text-align: center;
}

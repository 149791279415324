.link-outer-container {
    width: 100%;
    border-bottom: 10px solid transparent;
}

.link-container {
    width: 100%;
    min-height: 100px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 0 3px 2px #00000015;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.link-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 15px solid transparent;
    align-items: center;
}

.link-path {
    flex-shrink: 0;
    font-weight: 700;
}

.link-path > a {
    color: #1b52dd;
    text-decoration: none;
    font-size: 28px;
}

.link-url > a {
    color: #66748d;
    text-decoration: none;
}

.link-copy {
    width: 32px;
    height: 24px;
    background-size: 20px;
    background-position: right;
    background-repeat: no-repeat;
    cursor: pointer;
    flex-shrink: 0;
}

.link-edit {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    flex-shrink: 0;
}

.link-delete {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
    flex-shrink: 0;
}

.link-url {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-bottom: 10px solid transparent;
}

.link-url-input {
    width: 100%;
    margin-bottom: 15px;
    border: none;
    border-bottom: 1px solid #adadad;
    color: #66748d;
    font-size: 16px;
    outline: none;
}

.link-edit-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 15px solid transparent;
}

.link-cancel, .link-save {
    padding: 5px 20px;
    cursor: pointer;
}

.link-save {
    font-weight: 700;
    line-height: 18px;
}

.space-between {
    width: 100%;
}
.header {
    flex-shrink: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 10%;
    background-color: #004182;
    box-shadow: 0 0 10px #0f312e;
    background-size: cover;
    background-position: center center;
    z-index: 10;
    display: flex;
    align-items: center;
    padding-left: 20px;
    min-height: 90px;
}

.header-logo {
    background: url('../pictures/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 320px;
    height: 60px;
    flex-shrink: 0;
}

.header-width-one-hundred {
    width:100%;
    height: 100%;
}

.qr-code-panel-container {
    width: 100%;
}

.qr-code-panel-header {
    width: 100%;
    padding: 0 0 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.qr-code-container {
    width: 100%;
    padding: 30px 0;
    display: flex;
    justify-content: center;
}

.qr-code-panel-open-icon {
    font-weight: 700;
    width: 15px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: 12px;
    flex-shrink: 0;
}

.qr-code-panel-open-label {
    flex-shrink: 0;
}

.space-between {
    width: 100%;
}

.space-20px {
    width: 15px;
    flex-shrink: 0;
}
.links-container {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.links-label {
    font-weight: 700;
    color: #003365;
    font-size: 26px;
}

.links-add {
    display: flex;
    cursor: pointer;
    height: 32px;
    width: 32px;
    font-size: 26px;
    background-color: white;
    padding: 0 10px;
    border-radius: 5px;
    box-shadow: 0 0 3px 2px #00000015;
    justify-content: center;
    align-items: center;
}

.links-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 15px solid transparent;
}

.search-line {
    width: 100%;
    border-bottom: 15px solid transparent;
    padding-right: 10px;
}

.search-input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #adadad;
    color: #66748d;
    font-size: 16px;
    outline: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: right;
}